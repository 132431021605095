import React from 'react';
import { Stack, Table, TableBody, TableCell, TableHead, TableRow, Button, Typography } from '@mui/material';
import { faChartMixed, faTrashCan, faUserGroup } from '@fortawesome/pro-regular-svg-icons';
import { formatDate } from '../../helpers/date_utils';
import ActionMenuButton from '../shared/action_menu_button';

const SurveyCollectionsTable = ({ survey_collections, onEdit, onDelete, permissionProps, baseUrl, view }) => {
  const { can_delete_survey_collection, can_update_survey_collection, can_manage_responses } = permissionProps;
  const isDataAnalysisTable = view === 'data_analysis';

  const getMenuItems = (collection) => {
    const collectionId = collection.id;
    const menuItems = [];

    if (collection?.can_view_participants) {
      menuItems.push({
        label: 'Participants',
        icon: faUserGroup,
        onClick: () => {
          const source = isDataAnalysisTable ? 'data_analysis' : 'index';
          window.location.href = `${baseUrl}/${collectionId}/participants?source=${source}`;
        },
      });
    }

    if (can_delete_survey_collection) {
      menuItems.push({
        label: 'Delete',
        icon: faTrashCan,
        onClick: () => onDelete(collectionId),
      });
    }

    if (can_manage_responses) {
      menuItems.push({
        label: 'Manage responses',
        icon: faChartMixed,
        onClick: () => (window.location.href = `${baseUrl}/${collectionId}/response_management`),
      });
    }

    return menuItems;
  };

  const renderCellLink = (collection, cellValue) => {
    return (
      <Typography
        component="span"
        sx={{
          color: 'primary.main',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => (window.location.href = `${baseUrl}/${collection.id}/participants`)}
      >
        {cellValue}
      </Typography>
    );
  };

  const renderCompletedFraction = (collection) => {
    const completedFraction = collection.completed_fraction ? `${collection.completed_fraction}` : '0/0';
    if (collection?.can_view_participants && completedFraction !== '0/0') {
      return renderCellLink(collection, completedFraction);
    }
    return completedFraction;
  };

  const renderCompletedPercentage = (collection) => {
    const completedPercentage = collection.completed_percentage ? `${collection.completed_percentage}%` : '0%';
    if (collection?.can_view_participants && collection.completed_percentage) {
      return renderCellLink(collection, completedPercentage);
    }
    return completedPercentage;
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Created by</TableCell>
          <TableCell>Created on</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Completed (N/X)</TableCell>
          <TableCell>Completed (%)</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {survey_collections.map((collection) => (
          <TableRow key={collection.id}>
            <TableCell>{collection.name}</TableCell>
            <TableCell>{collection.created_by.full_name}</TableCell>
            <TableCell>{formatDate(collection.created_at)}</TableCell>
            <TableCell>{collection.status}</TableCell>
            <TableCell>{renderCompletedFraction(collection)}</TableCell>
            <TableCell>{renderCompletedPercentage(collection)}</TableCell>
            <TableCell>
              {isDataAnalysisTable ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => (window.location.href = `${baseUrl}/${collection.id}/data_analysis_show`)}
                >
                  View
                </Button>
              ) : (
                <Stack direction="row" spacing={1} alignItems="center">
                  {getMenuItems(collection).length > 0 && <ActionMenuButton menuItems={getMenuItems(collection)} />}
                  {can_update_survey_collection && (
                    <Button variant="contained" color="secondary" onClick={() => onEdit(collection.id)}>
                      Edit
                    </Button>
                  )}
                </Stack>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SurveyCollectionsTable;
