import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import { ConfirmDialogProvider } from '../../components/modals/confirm_dialog';
import { Paper } from '@mui/material';
import SurveyCollectionsNav from '../../components/survey_collections/nav';
import SurveysReport from '../../components/surveys_report/surveys_report';

const Container = (props) => {
  const { org_id, survey_collection, base_url, links, permission_props, themes, themesShorthands, originalResponses } = props;
  return (
    <>
      <SurveyCollectionsNav links={links} permissionProps={permission_props} activeTab="survey_collections" backUrl={base_url} />
      <Paper className="p-3 mt-4">
        <h6>{survey_collection?.name}</h6>
        <SurveysReport originalResponses={originalResponses} themes={themes} themesShorthands={themesShorthands} />
      </Paper>
    </>
  );
};

Container.propTypes = {
  org_id: PropTypes.number.isRequired,
  base_url: PropTypes.string.isRequired,
  links: PropTypes.object.isRequired,
  permission_props: PropTypes.object,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <ConfirmDialogProvider>
          <Container {...props} />
        </ConfirmDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};
